import React, { FC } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Layout from "../components/Layout";
import SEO from "../components/SEO";
import ButtonLink from "../components/ButtonLink";
import PurpleAccentHero from "../components/PurpleAccentHero";
import ResponsiveVideo from "../components/ResponsiveVideo";
import IndexCoreValuesSection from "../components/index/IndexCoreValuesSection";

import InternalButtonLink from "../components/InternalButtonLink";
import { IMAGES_PATH } from "../utils/constants";
import CookiesSnackbar from "../components/CookiesSnackbar";
import { useTypography } from "../styles/Typography";
import { makeStyles } from "@material-ui/styles";
import TrendUpIcon from "../components/icons/TrendUpIcon";
import { useTheme } from "@material-ui/core";
import { WORKSHOP } from "../utils/routes";
import DiscoverMoreBanner from "../components/DiscoverMoreBanner";

const useStyles = makeStyles({
  titleHeadingGradientWrapper: {
    width: "100%",
    height: "100%",
    background:
      "linear-gradient(110.11deg, #FFFFFF 38%, rgba(255, 255, 255, 0) 67.89%)",
  },
  titleHeadingText: {
    marginLeft: 100,
    maxWidth: 360,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    height: "100%",

    color: "rgba(0, 0, 0, 0.8)",
    letterSpacing: "0.25px",
  },
  subHeading: {
    fontFamily: "Ubuntu",
    fontWeight: 400,
    fontSize: 20,
    lineHeight: "32px",
  },
  titleDescription: {
    fontFamily: "Ubuntu",
    fontSize: 16,
    fontWeight: 400,
    lineHeight: "24px",
  },
  introducingText: {
    fontSize: 12,
    lineHeight: "16px",
    letterSpacing: "2px",
    textTransform: "uppercase",
    fontWeight: 400,
    marginBottom: 24,
  },
  videoSection: {
    textAlign: "center",
    maxWidth: 890,
    margin: "0 auto",
    "& h2": {
      marginBottom: 24,
    },
    "& video": {
      boxShadow:
        "0 4px 2px -2px rgba(0, 0, 0, 0.08), 0px 12.5px 128px rgba(0, 0, 0, 0.08)",
      borderRadius: 4,
    },
  },
  challengeSection: {
    textAlign: "center",
    marginTop: 40,
    maxWidth: 990,
    margin: "0 auto",
    "& h2": {
      marginBottom: 24,
    },
  },
  challengeBars: {
    textAlign: "left",
    listStyleType: "none",
    margin: 0,
    padding: 0,
    color: "white",
    "& li": {
      display: "flex",
      height: 104,
      maxWidth: 990,
      fontSize: 24,
      lineHeight: "28px",
      "& div": {
        display: "flex",
        alignItems: "center",
        height: "100%",
        paddingLeft: 32,
      },
      "& span": {
        fontSize: 48,
        lineHeight: "56px",
        marginRight: 16,
      },
    },
  },
  firstBarLeftSide: {
    width: "80%",
    backgroundColor: "#8a79b9",
    zIndex: 1,
    "&&": {
      whiteSpace: "normal",
    },
    "@media (max-width: 542px)": {
      fontSize: "12px",
    },
    "@media (max-width: 453px)": {
      fontSize: "11px",
      lineHeight: "16px",
    },
  },
  firstBarRightSide: {
    width: "20%",
    backgroundColor: "#ada4cf",
    "&&": {
      paddingLeft: 0,
    },
  },
  secondBarLeftSide: {
    width: "90%",
    backgroundColor: "#6d57a8",
    zIndex: 1,
    "&&": {
      whiteSpace: "normal",
    },
    "@media (max-width: 542px)": {
      fontSize: "12px",
    },
    "@media (max-width: 453px)": {
      fontSize: "11px",
      lineHeight: "16px",
    },
  },
  secondBarRightSide: {
    width: "10%",
    backgroundColor: "#ada4cf",
    "&&": {
      paddingLeft: 0,
    },
  },
  thirdBar: {
    width: "100%",
    backgroundColor: "#654EA3",
    "&&": {
      whiteSpace: "normal",
    },
    "@media (max-width: 542px)": {
      fontSize: "12px",
    },
    "@media (max-width: 453px)": {
      fontSize: "11px",
      lineHeight: "16px",
    },
  },
  thirdBarRightSide: {
    width: "0%",
    backgroundColor: "#ada4cf",
    "&&": {
      paddingLeft: 0,
    },
  },
  betterWaySection: {
    background: "white",
    textAlign: "center",
    maxWidth: 690,
    padding: "60px 55px",
    margin: "0 auto",
    "& h2": {
      marginBottom: 24,
    },
  },
  statisticsSection: {
    margin: "40px auto",
    maxWidth: 1200,
  },
  statisticsList: {
    listStyleType: "none",
    padding: 0,
    margin: 0,

    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",

    "& li": {
      width: 224,
      margin: "0 8px",
      "& svg": {
        width: 32,
        height: 33,
      },
      "& span": {
        fontSize: 48,
        lineHeight: "56px",
        marginLeft: 10,
      },
    },
  },
  "@media (max-width: 959px)": {
    titleHeadingGradientWrapper: {
      background: "transparent",
    },
    titleHeadingText: {
      marginLeft: 0,
      padding: "4px 24px",
      maxWidth: "100%",
      marginBottom: 20,
      "& h1": {
        fontSize: 20,
        lineHeight: "24px",
        fontWeight: 400,
      },
      "& a": {
        margin: "0 auto",
      },
    },
    challengeSection: {
      "& h2": {
        fontSize: 20,
        lineHeight: "24px",
        fontWeight: 400,
      },
    },
    challengeBars: {
      "& li": {
        display: "flex",
        height: 56,
        fontSize: 14,
        lineHeight: "24px",
        letterSpacing: "0.1px",
        "& span": {
          fontSize: 20,
          lineHeight: "24px",
          marginRight: 8,
        },
        "& div": {
          paddingLeft: 16,
          whiteSpace: "nowrap",
        },
      },
    },
    betterWaySection: {
      padding: 16,
      "& h2": {
        textAlign: "left",
        fontSize: 20,
        lineHeight: "24px",
        fontWeight: 400,
        marginBottom: 16,
      },
      "& p": {
        textAlign: "left",
        fontSize: 14,
        lineHeight: "21px",
        letterSpacing: "0.25px",
        fontWeight: 400,
      },
    },
    statisticsSection: {
      "& h2": {
        fontSize: 12,
        lineHeight: "16px",
        letterSpacing: "2px",
        textTransform: "uppercase",
        textAlign: "center",
        fontWeight: 400,
        maxWidth: 225,
        margin: "0 auto",
      },
    },
    statisticsList: {
      flexWrap: "nowrap",
      justifyContent: "flex-start",
      overflowX: "scroll",
      marginTop: 16,
      "& li": {
        flexShrink: 0,
        "& div": {
          marginTop: -8,
        },
        "& svg": {
          transform: "scale(0.5)",
          marginRight: -8,
          marginBottom: -8,
        },
        "& span": {
          fontSize: 32,
          lineHeight: "40px",
          letterSpacing: "0.25px",
          fontWeight: 500,
        },
      },
    },
  },
});

const IndexPage: FC = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const typo = useTypography();

  const isMobile = useMediaQuery("(max-width:959px)");

  const data = useStaticQuery(graphql`
    query {
      desktopHomePeopleWorkingTopImage: file(
        relativePath: { eq: "index/home-people-working-top-desktop.jpeg" }
      ) {
        childImageSharp {
          fluid(quality: 75, maxWidth: 2160) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mobileHomePeopleWorkingTopImage: file(
        relativePath: { eq: "index/home-people-working-top-mobile.jpeg" }
      ) {
        childImageSharp {
          fluid(quality: 75, maxWidth: 960) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      teamsIcon: file(relativePath: { eq: "officeIcons/teams.png" }) {
        childImageSharp {
          fixed(width: 94) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      sharepointIcon: file(relativePath: { eq: "officeIcons/sharepoint.png" }) {
        childImageSharp {
          fixed(width: 94) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      oneDriveIcon: file(relativePath: { eq: "officeIcons/one-drive.png" }) {
        childImageSharp {
          fixed(width: 94) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  const desktopVideoUrl = `${IMAGES_PATH}videos/nuliaVideo1.mp4`;
  const posterImageUrl = `${IMAGES_PATH}videos/nuliaVideo-keyframe1.jpg`;
  const captionsFile = `${IMAGES_PATH}videos/nuliaVideo1.vtt`;

  return (
    <Layout>
      <SEO title="Home" />
      <DiscoverMoreBanner />
      <PurpleAccentHero
        desktopImage={
          data.desktopHomePeopleWorkingTopImage.childImageSharp.fluid
        }
        mobileImage={data.mobileHomePeopleWorkingTopImage.childImageSharp.fluid}
        alt="People working together in office"
      >
        <div className={classes.titleHeadingGradientWrapper}>
          <section className={classes.titleHeadingText}>
            <h1 className={typo.h4} style={{ marginBottom: 8 }}>
              Change as a Service
            </h1>
            <h2 className={typo.h6} style={{ marginBottom: 32 }}>
              Driving organizational change through Copilot & Microsoft 365
              adoption
            </h2>
            <h2 className={typo.h6} style={{ marginBottom: 32 }}>
              Maximizing employee productivity and unlocking the full value of
              Copilot & ALL of Microsoft 365
            </h2>
            <InternalButtonLink
              variant="orange"
              href={WORKSHOP}
              style={{ zIndex: 1 }}
            >
              Free Workshop
            </InternalButtonLink>
          </section>
        </div>
      </PurpleAccentHero>
      <section className={classes.videoSection}>
        {isMobile && (
          <h2>
            <div className={classes.introducingText}>
              INTRODUCING NULIA WORKS
            </div>
            Change as a Service for Microsoft 365
          </h2>
        )}
        {!isMobile && <h2>Change as a Service for Microsoft 365</h2>}
        <ResponsiveVideo
          desktopSrc={desktopVideoUrl}
          posterImage={posterImageUrl}
          captionsFile={captionsFile}
        />
      </section>
      <section className={classes.challengeSection}>
        <h2>Microsoft 365 Adoption is Stuck in the Pre-Digital Era</h2>
        <ul className={classes.challengeBars}>
          <li>
            <div className={classes.firstBarLeftSide}>
              <span>60%</span> of Microsoft 365 productivity benefits are at
              risk if the chosen adoption solution fails to drive lasting change
            </div>
            <div className={classes.firstBarRightSide} />
          </li>
          <li>
            <div className={classes.secondBarLeftSide}>
              <span>70%</span> of traditional adoption solutions fail, including
              training, so the productivity benefits of Microsoft 365 are not
              realized
            </div>
            <div className={classes.secondBarRightSide} />
          </li>
          <li>
            <div className={classes.thirdBar}>
              <span>80%</span> of employees continue to use duplicate, shadow IT
              apps because the adoption solution failed to get them using
              Microsoft 365​
            </div>
            <div className={classes.thirdBarRightSide} />
          </li>
        </ul>
      </section>
      <section className={classes.betterWaySection}>
        <h2>Change as a Service is a Better Way</h2>
        <p>
          Change as a Service is a data-driven solution that proves real
          Microsoft 365 adoption is happening and organizational change is being
          realized and sustained. Powered by the Nulia Works platform and led by
          change management experts using our Circle of Nulia methodology,
          Change as a Service provides a brand-new way for organizations to
          unlock the full value of their Microsoft 365 investment, including
          Copilot & ALL of the apps included in the suite, while guiding their
          employees to develop the digital skills they need to realize their
          potential.
        </p>
        <ButtonLink href="/product">DISCOVER MORE</ButtonLink>
      </section>
      <IndexCoreValuesSection isMobile={isMobile} />
      <CookiesSnackbar />
    </Layout>
  );
};

export default IndexPage;

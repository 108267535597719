import React, { FC } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  workForYou: {
    background:
      "linear-gradient(90deg, rgba(101, 78, 163, 0.08) 77%, transparent 77%)",
    maxWidth: 1200,
    margin: "0 auto",
    padding: 32,
    display: "flex",
    justifyContent: "space-between",
    "& h2": {
      marginBottom: 32,
    },
  },
  workForYouText: {
    flexBasis: "65%",
    marginRight: 40,
  },
  workForYouImageDesktop: {
    flexBasis: "35%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  },
  workForYouImageMobile: {
    display: "none",
  },
  workForYouImageWrapper: {
    position: "relative",
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& div": {
      position: "absolute",
    },
  },
  visible: {
    visibility: "visible",
    opacity: 1,
    transition: "opacity 0.3s linear",
  },
  hidden: {
    visibility: "hidden",
    opacity: "0",
    transition: "visibility 0s 0.3s, opacity 0.3s linear",
  },
  mobileSubheading: {
    fontSize: 20,
    lineHeight: "24px",
    letterSpacing: "0.25px",
  },
  tabsWrapper: {
    fontFamily: "Ubuntu",
    fontWeight: 500,
    "& p": {
      marginBottom: 0,
    },
  },
  tabText: {
    fontWeight: 400,
    marginTop: 8,
  },
  "@media (max-width: 1200px)": {
    tabSubtitle: {
      padding: "0 32px",
    },
  },
  [theme.breakpoints.down("md")]: {
    workForYou: {
      background:
        "linear-gradient(90deg, rgba(101, 78, 163, 0.08) 77%, transparent 77%)",
      maxWidth: 1200,
      margin: "0 auto",
      padding: 32,
      display: "flex",
      jusitfyContent: "space-between",
      "& h2": {
        marginBottom: 32,
      },
    },
    workForYouText: {
      flexBasis: "65%",
      marginRight: 40,
    },
    workForYouImageDesktop: {
      flexBasis: "35%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
    },
    workForYouImageMobile: {
      display: "none",
    },
    workForYouImageWrapper: {
      position: "relative",
      height: "100%",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& div": {
        position: "absolute",
      },
    },
    visible: {
      visibility: "visible",
      opacity: 1,
      transition: "opacity 0.3s linear",
    },
    hidden: {
      visibility: "hidden",
      opacity: "0",
      transition: "visibility 0s 0.3s, opacity 0.3s linear",
    },
    indicatorOverride: {
      backgroundColor: "red",
    },
    mobileSubheading: {
      fontSize: 20,
      lineHeight: "24px",
      letterSpacing: "0.25px",
    },
    tabsWrapper: {
      "& p": {
        marginBottom: 0,
      },
    },
    [theme.breakpoints.down("md")]: {
      workForYou: {
        background: "rgba(101,78,163,0.08)",
        padding: "16px 0",
        "& h2, & h3": {
          padding: "0 32px",
        },
        "& p": {
          padding: "0 32px",
        },
      },
      workForYouText: {
        flexBasis: "100%",
        marginRight: 0,
        "& p": {
          fontWeight: 400,
        },
      },
      workForYouImageDesktop: {
        display: "none",
      },
      workForYouImageMobile: {
        display: "inline-block",
      },
      tabsWrapper: {
        padding: "0 9px",
        maxWidth: "calc(100vw - 18px)",
      },
    },
  },
}));

interface IPropsTabPanel {
  value: number;
  index: number;
  isMobile?: boolean;
}

const TabPanel: FC<IPropsTabPanel> = ({ children, value, index, isMobile }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      style={{
        background: "white",
        padding: isMobile ? 16 : "16px 25px 24px 25px",
        fontWeight: 500,
        letterSpacing: "0.15px",
        minHeight: 112,
      }}
    >
      {value === index && children}
    </div>
  );
};

interface IPropsAnimated {
  isVisible: boolean;
}

const Animated: FC<IPropsAnimated> = ({ isVisible, children }) => {
  const classes = useStyles();

  return (
    <div className={isVisible ? classes.visible : classes.hidden}>
      {children}
    </div>
  );
};

const tabStyleOverrides = {
  color: "rgba(0,0,0,0.64)",
  fontFamily: "Ubuntu",
  fontSize: 12,
  fontWeight: 500,
  letterSpacing: "1px",
  marginBottom: 6,
  marginTop: 1,
};

interface IPropsIndexCore {
  isMobile?: boolean;
}

const IndexCoreValuesSection: FC<IPropsIndexCore> = ({ isMobile }) => {
  const classes = useStyles();

  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const data = useStaticQuery(graphql`
    query {
      measureEvaluateImage: file(
        relativePath: { eq: "index/measure-evaluate.png" }
      ) {
        childImageSharp {
          fixed(width: 410, pngQuality: 80) {
            ...GatsbyImageSharpFixed
          }
          fluid(maxWidth: 959, pngQuality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      personalizeGuideImage: file(
        relativePath: { eq: "index/personalize-guide.png" }
      ) {
        childImageSharp {
          fixed(width: 369, pngQuality: 80) {
            ...GatsbyImageSharpFixed
          }
          fluid(maxWidth: 959, pngQuality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      engageRewardImage: file(relativePath: { eq: "index/engage-reward.png" }) {
        childImageSharp {
          fixed(width: 382, pngQuality: 80) {
            ...GatsbyImageSharpFixed
          }
          fluid(maxWidth: 959, pngQuality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <section className={classes.workForYou}>
      <div className={classes.workForYouText}>
        <h2 style={{ lineHeight: "1.5em" }}>
          Change as a Service is a <i>Must Have</i> for your Bottom-Line
        </h2>

        <h3 className={classes.subtitle}>
          Providing up to a 11x Return on Investment
        </h3>

        <p>
          A Change as a Service solution saves organizations money as Microsoft
          365 & Copilot adoption eliminates the need for duplicate IT apps,
          expensive Microsoft 365 training, and high-cost help desk support.
          Change as a Service is a highly scalable solution, powered by the
          Nulia Works platform, which cost effectively drives Microsoft 365
          adoption and change in an employee’s natural flow of work.
        </p>

        <div className={classes.tabsWrapper}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons={isMobile ? "on" : "off"}
            aria-label="The three core values of Nulia Works"
            style={{
              background: "white",
              borderRadius: "4px",
            }}
            TabIndicatorProps={{
              children: (
                <span
                  style={{
                    height: 4,
                    maxWidth: 138,
                    width: "100%",
                    backgroundColor: "#654EA3",
                    borderRadius: 4,
                  }}
                />
              ),
              style: {
                height: 4,
                display: "flex",
                justifyContent: "center",
                backgroundColor: "transparent",
              },
            }}
          >
            {/* TODO: A11y Props for tabs */}
            <Tab label="MEASURE & EVALUATE PILLAR" style={tabStyleOverrides} />
            <Tab label="PERSONALIZE & GUIDE PILLAR" style={tabStyleOverrides} />
            <Tab label="ENGAGE & REWARD PILLAR" style={tabStyleOverrides} />
          </Tabs>
          <TabPanel value={tabValue} index={0} isMobile={isMobile}>
            {isMobile && (
              <Img
                fluid={data.measureEvaluateImage.childImageSharp.fluid}
                alt="A woman smiling while using her laptop"
              />
            )}
            <span className={classes.tabSubtitle}>
              Nulia Works Measure & Evaluate Pillar
            </span>
            <p className={classes.tabText}>
              A Change as a Service solution powered by Nulia Works continuously
              measures Microsoft 365 usage to determine employee progress in
              developing skills toward Microsoft 365 adoption. Nulia Works
              evaluates progress toward achieving organizational Outcomes which
              sustains change and unlocks business value from a Microsoft 365
              investment.
            </p>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            {isMobile && (
              <Img
                fluid={data.personalizeGuideImage.childImageSharp.fluid}
                alt="A man smiling while using his laptop"
              />
            )}
            <span className={classes.tabSubtitle}>
              Nulia Works Personalize & Guide Pillar
            </span>
            <p className={classes.tabText}>
              A Change as a Service solution powered by Nulia Works personalizes
              a Microsoft 365 Skill development and Outcome attainment plan for
              each organization and individual employee. Nulia Works
              continuously guides employees to complete the activities required
              to develop and maintain Skills & attain Outcomes.
            </p>
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            {isMobile && (
              <Img
                fluid={data.engageRewardImage.childImageSharp.fluid}
                alt="Another man smiling while using his laptop"
              />
            )}
            <span className={classes.tabSubtitle}>
              Nulia Works Engage & Reward Pillar
            </span>
            <p className={classes.tabText}>
              A Change as a Service solution powered by Nulia Works provides
              achievements, engagement points, and badges to recognize and
              reward progress in developing skills and reaching Outcomes. Nulia
              Works makes the Microsoft 365 adoption and change process fun,
              exciting, and personally rewarding for each employee.
            </p>
          </TabPanel>
        </div>
      </div>
      <div className={classes.workForYouImageDesktop}>
        <div className={classes.workForYouImageWrapper}>
          <Animated isVisible={tabValue === 0}>
            <Img
              fixed={data.measureEvaluateImage.childImageSharp.fixed}
              alt="A woman smiling while using her laptop"
            />
          </Animated>
          <Animated isVisible={tabValue === 1}>
            <Img
              fixed={data.personalizeGuideImage.childImageSharp.fixed}
              alt="A man smiling while using his laptop"
            />
          </Animated>
          <Animated isVisible={tabValue === 2}>
            <Img
              fixed={data.engageRewardImage.childImageSharp.fixed}
              alt="Another man smiling while using his laptop"
            />
          </Animated>
        </div>
      </div>
    </section>
  );
};

export default IndexCoreValuesSection;
